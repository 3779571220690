<!-- sidebar -->
<div class="sidebar p-0 m-0">
  <div class="row m-0 h-100 center">
    <div class="col-sm-12 text-center">
      <!-- <h1><a [routerLink]="['/']" class="title">Keeku</a></h1> -->
      <a href="/homepage" class="title"
        ><img
          class="img-fluid btn-img-no-effect"
          src="../../../../assets/images/logo-text.png"
          alt=""
      /></a>
    </div>
    <div class="col-12 mt-1 mb-2 text-center">
      <h4 class="fw-600 mb-3">
        Where Real People Shape Real Conversations -- No Anonymous Accounts
        Allowed To Comment!
      </h4>
      <p class="sidebar-text">Identity verification enhanced by Veriff.</p>
      <p class="sidebar-text">Rest assured, your data is secure.</p>
      <img src="../../../../assets/images/Veriff-Logo.svg" class="mt-2">
    </div>
  </div>
</div>
<!-- sidebar Ends-->
