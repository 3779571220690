<!-- claim Profile -->
<div class="profile-content px-0 m-0" [ngClass]="{ 'is-popup py-3': !isPopup, 'py-0 h-100': isPopup }">
  <div class="px-0 scrollbar-adjust profile-container"
    [ngClass]="{ 'container px-sm-2 px-md-4 py-3 py-md-4': !isPopup, 'py-0 br-0 h-100': isPopup }">
    <div class="row m-0" *ngIf="!isPopup">
      <div class="col-md-4 col-lg-3 text-center text-md-left">
        <h5 class="theme-color fw-700">Create page</h5>
      </div>
      <!-- .col-md-12 -->
      <div class="col-sm-8 col-lg-6 d-flex flex-column mt-3 mt-sm-0" *ngIf="user?.defaultProfileId == null">
        <div class="progress signup-progress-bar">
          <div class="progress-bar" role="progressbar" [style.width.%]="progressBar" [attr.aria-valuenow]="progressBar"
            aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div>
          <p class="font-14 fw-500 mt-2">{{progressBar}}%</p>
        </div>
      </div>
    </div>
    <form class="row m-0 square-form vertical-collapse needs-validation"
      [ngClass]="{ 'mt-3': !isPopup, 'lt-custom': isPopup }" (ngSubmit)="(categoryForm.form.valid)"
      #categoryForm="ngForm" novalidate>
      <div class="col-12" [ngClass]="{
          'col-md-4 col-lg-3 pr-md-4': !isPopup,
          'sticky-top lt-header': isPopup
        }">
        <div class="nav flex-column nav-pills multi-steps-vertical-nav" [ngClass]="{ 'multi-steps': isPopup }"
          id="v-pillsTab" role="tablist" aria-orientation="vertical">
          <button class="nav-link active row mt-0" id="profileDetailsTab" (click)="showTab(link, $event)" #link
            data-toggle="pill" data-target="#profileDetails" role="tab" aria-controls="profileDetails"
            aria-selected="true">
            <span class="step-icon d-none" [ngClass]="{ 'd-md-inline-block': !isPopup }">1</span>
            <span class="d-none" [ngClass]="{ 'd-md-inline-block': !isPopup }">.</span>
            Basic details
            <span class="col-auto step-icon ml-auto" [ngClass]="{ 'd-md-none': !isPopup }">
              Step <span class="step-current">1</span> of
              <span class="step-total">1</span>
            </span>
          </button>
          <ng-container *ngIf="selectedCategoryId != []">
            <ng-container *ngFor="
                let section of sectionList;
                let j = index;
                let last = last
              ">
              <button class="nav-link row mt-0" [id]="'collapseCat_' + j + 'Tab'" (click)="showTab(link, $event)" #link
                data-toggle="pill" [attr.data-target]="'#collapseCat_' + j" role="tab"
                [attr.aria-controls]="'collapseCat_' + j" aria-selected="true">
                <!-- [disabled]="(selectedSubcategoryId.length === 0) == true" -->
                <span class="step-icon d-none" [ngClass]="{ 'd-md-inline-block': !isPopup }"></span>
                <span class="d-none" [ngClass]="{ 'd-md-inline-block': !isPopup }">.</span>
                {{ section.name }}
                <span class="step-icon ml-auto" [ngClass]="{ 'd-md-none': !isPopup }">
                  Step <span class="step-current">1</span> of
                  <span class="step-total">x</span>
                </span>
                <span *ngIf="last">{{ elementNumbering() }}</span>
              </button>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="
              let subCategory of subcategoryList;
              let i = index;
              let last = last
            ">
            <ng-container *ngFor="
                let section of subCategory.sections;
                let j = index;
                let verylast = last
              ">
              <button class="nav-link row" [id]="'collapseSubcat_' + i + '_' + j + 'Tab'"
                (click)="showTab(link, $event)" #link data-toggle="pill"
                [attr.data-target]="'#collapseSubcat_' + i + '_' + j" role="tab"
                [attr.aria-controls]="'collapseSubcat_' + i + '_' + j" aria-selected="true">
                <span class="step-icon d-none" [ngClass]="{ 'd-md-inline-block': !isPopup }"></span>
                <span class="d-none" [ngClass]="{ 'd-md-inline-block': !isPopup }">.</span>
                {{ section.name }}
                <span class="step-icon ml-auto" [ngClass]="{ 'd-md-none': !isPopup }">
                  Step <span class="step-current">1</span> of
                  <span class="step-total">x</span>
                </span>
                <span *ngIf="last && verylast">{{ elementNumbering() }}</span>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="col-12" [ngClass]="{
          'col-md-8 col-lg-9 pl-md-4': !isPopup,
          'lt-content': isPopup
        }">
        <div class="tab-content" id="v-pillsTabContent">
          <div class="tab-pane fade show active" id="profileDetails" role="tabpanel"
            aria-labelledby="profileDetailsTab">
            <div class="row col-12 px-0 pb-2 mx-0">
              <h6 class="fw-600 d-none" [ngClass]="{ 'd-md-block': !isPopup }">
                Basic details
              </h6>
            </div>
            <div class="row bg-dark-white p-3 br-10 mx-0" ngModelGroup="profileDetails_Group">
              <div class="col-12 text-center py-4" [ngClass]="{ 'col-md-auto py-md-0': !isPopup }">
                <button class="btn btn-img-rounded profile-image fix-avtar-7 m-auto" (click)="$event.preventDefault()">
                  <img [src]="selectedImg" alt="Image" onerror="this.src='../../../assets/images/noprofile2.png';" />
                  <div class="col-12 profile-upload row m-0 align-content-end cursor-auto d-none d-md-flex">
                    <button type="button" class="col mx-auto btn btn-outline-black p-1 br-0"
                      (click)="$event.preventDefault()" data-toggle="modal" data-target="#uploadProfileModal">
                      <i class="fi font-14 flaticon-edit"></i>
                    </button>
                    <button type="button" class="col mx-auto btn btn-outline-black p-1 br-0"
                      (click)="$event.preventDefault()" data-toggle="modal" data-target="#viewProfileModal">
                      <i class="fi font-14 flaticon-eye"></i>
                    </button>
                    <button type="button" class="col mx-auto btn btn-outline-black font-danger p-1 br-0"
                      (click)="deleteProfilePicture()" data-toggle="modal">
                      <i class="fi font-14 flaticon-delete"></i>
                    </button>
                  </div>
                </button>

                <div class="col-12 row m-0 align-content-end cursor-auto mt-3 d-md-none">
                  <button type="button" class="col-auto mx-auto btn btn-outline-black p-1 br-0"
                    (click)="$event.preventDefault()" data-toggle="modal" data-target="#uploadProfileModal">
                    <i class="fi font-14 flaticon-edit"></i>
                  </button>
                  <button type="button" class="col-auto mx-auto btn btn-outline-black p-1 br-0"
                    (click)="$event.preventDefault()" data-toggle="modal" data-target="#viewProfileModal">
                    <i class="fi font-14 flaticon-eye"></i>
                  </button>
                  <button type="button" class="col-auto mx-auto btn btn-outline-black p-1 br-0"
                    (click)="deleteProfilePicture()" data-toggle="modal">
                    <i class="fi font-14 flaticon-delete"></i>
                  </button>
                </div>
                <div class="clearfix"></div>
              </div>
              <div class="col-md row px-0 mx-0" [ngClass]="{ 'col-md': !isPopup }" [ngClass]="{
                'pb-sm-5 pb-4 mb-sm-0 mb-3 ': showErrors && tagname.invalid
              }">
                <div class="col-12" [ngClass]="{ 'col-lg-8': !isPopup }">
                  <div class="form-group mt-0 mb-3">
                    <!-- var fieldID, fieldName, fieldPattern = *, fieldLabel, fieldErrorMessage -->
                    <input [(ngModel)]="profile.profileName" blankValidation="{{ profile.profileName }}" type="text"
                      id="profileName" [disabled]="firstLogin" [ngClass]="{
                        'form-control': true,
                        'has-value': !!profileName.value
                      }" name="profileName" #profileName="ngModel" required />
                    <label for="profileName">Page Name<span>*</span></label>
                    <div *ngIf="showErrors && profileName.invalid" class="invalid-feedback">
                      <span *ngIf="profileName?.errors?.['required']">This field is required</span>
                      <div *ngIf="
                          profileName?.errors?.['whitespace'] &&
                          !profileName?.errors?.['required']
                        " class="invalid-feedback">
                        <span *ngIf="profileName?.errors?.['whitespace']">Blank Space not support</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12" [ngClass]="{ 'col-lg-8': !isPopup }">
                  <div class="form-group mt-0 mb-0"
                  [ngClass]="{  'basic-details-input': profile.tagName != null && profile.tagName != '' }">
                    <!-- var fieldID, fieldName, fieldPattern = *, fieldLabel, fieldErrorMessage -->
                    <input [(ngModel)]="profile.tagName" type="text" id="tagname" (focusout)="checkExistingKeekuHandle()"
                      [ngClass]="{
                        'form-control': true,
                        'has-value': !!tagname.value
                      }" name="tagname" #tagname="ngModel" pattern="^[^\s][A-Za-z0-9/_/-/.]+$" required  NoLeadingWhitespace/>
                    <label for="tagname">Handle<span>*</span></label>
                    <!-- <i class="bar"></i> -->
                    <div *ngIf="showErrors && tagname.invalid" class="invalid-feedback">
                      <span *ngIf="tagname?.errors?.['required']">This field is required</span>
                      <span *ngIf="tagname?.errors?.['pattern']">Please enter a valid Handle. It should be alphanumeric
                        and it does not contain spaces.</span>
                    </div>
                    <div *ngIf="tagNameFound" class="invalid-feedback">
                      <span>Handle already exists, please try a different one.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col-12 px-0">
                <h6 class="font-black p-0_85em pt-3">Category</h6>
                <div class="cat_grid mx-0">
                  <div class="cat_box" *ngFor="
                      let item of categoryList | sortBy : 'asc' : 'displayOrder'
                    ">
                    <input type="radio" name="selectedCategoryId" [id]="'selectedCategoryId_' + item.id"
                      [value]="item.id" (change)="getSubcategoryAndSections($event)" #radio />
                    <label class="cat_box_data" [for]="'selectedCategoryId_' + item.id" *ngIf="!!item.hintText"
                      data-toggle="tooltip" data-placement="auto" [title]="item.hintText">
                      <img *ngIf="!item.iconPath" src="/assets/images/icon_logo.png" class="fix-avtar-3 p-1"
                        alt="Icon" />
                      <img *ngIf="!!item.iconPath" [src]="item.iconPath" class="fix-avtar-3 p-1"
                        onerror="this.src='../../../assets/images/noprofile2.png';" alt="Icon" />
                      <p class="font-12 font-black py-2">{{ item.name }}</p>
                    </label>
                    <label class="cat_box_data" [for]="'selectedCategoryId_' + item.id" *ngIf="!item.hintText">
                      <img *ngIf="!item.iconPath" src="/assets/images/icon_logo.png" class="fix-avtar-3 p-1"
                        alt="Icon" />
                      <img *ngIf="!!item.iconPath" [src]="item.iconPath"
                        onerror="this.src='../../../assets/images/noprofile2.png';" class="fix-avtar-3 p-1"
                        alt="Icon" />
                      <p class="font-12 font-black py-2">{{ item.name }}</p>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-12 px-0" *ngIf="subcategoryListForDataSource.length !== 0">
                <h6 class="font-black p-0_85em pt-3">Subcategory</h6>
                <div class="cat_grid mx-0">
                  <div class="cat_box" *ngFor="
                      let item of subcategoryListForDataSource
                        | sortBy : 'asc' : 'displayOrder'
                    ">
                    <input type="checkbox" name="subcategory" [id]="'selectedSubcategoryId_' + item.subCategoryId"
                      [value]="item.subCategoryId" (change)="getSubCategorySections($event)" />
                    <label class="cat_box_data" [for]="'selectedSubcategoryId_' + item.subCategoryId"
                      *ngIf="!!item.hintText" data-toggle="tooltip" data-placement="auto" [title]="item.hintText">
                      <img *ngIf="!item.iconPath" src="/assets/images/icon_logo.png" class="fix-avtar-3 p-1"
                        alt="Icon" />
                      <img *ngIf="!!item.iconPath" [src]="item.iconPath"
                        onerror="this.src='../../../assets/images/noprofile2.png';" class="fix-avtar-3 p-1"
                        alt="Icon" />
                      <p class="font-12 font-black pt-2 mb-0">
                        {{ item.name }}
                      </p>
                    </label>
                    <label class="cat_box_data" [for]="'selectedSubcategoryId_' + item.subCategoryId"
                      *ngIf="!item.hintText">
                      <img *ngIf="!item.iconPath" src="/assets/images/icon_logo.png" class="fix-avtar-3 p-1"
                        alt="Icon" />
                      <img *ngIf="!!item.iconPath" [src]="item.iconPath"
                        onerror="this.src='../../../assets/images/noprofile2.png';" class="fix-avtar-3 p-1"
                        alt="Icon" />
                      <p class="font-12 font-black pt-2 mb-0">
                        {{ item.name }}
                      </p>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="selectedCategoryId != []">
            <ng-container *ngFor="
                let section of sectionList | sortBy : 'asc' : 'displayOrder';
                let j = index
              ">
              <div class="tab-pane fade" [id]="'collapseCat_' + j" role="tabpanel"
                [attr.aria-labelledby]="'collapseCat_' + j + 'Tab'">
                <div class="row col-12 px-0 mx-0 align-items-center">
                  <h6 class="fw-600 d-none" [ngClass]="{ 'd-md-block': !isPopup }">
                    {{ section.name }}
                  </h6>
                  <button class="btn btn-black float-right ml-auto mw-auto" *ngIf="section.allowMultiple"
                    (click)="addMoreSection(section, selectedCategoryId)" type="button">
                    <i class="fi flaticon-plus font-18"></i>
                  </button>
                </div>
                <div *ngIf="section.hintText != '' && section.hintText != null">
                  {{ section.hintText }}
                </div>
                <div class="row" ngModelGroup="collapseCat_{{ j }}_Group">
                  <div class="col-12" [ngClass]="{ 'col-md-6': !isPopup }" *ngFor="
                      let field of section.fields
                        | sortBy : 'asc' : 'displayOrder';
                      let i = index
                    ">
                    <app-dynamic-fields (informParent)="parentWillTakeAction($event)" [field]="field" [uniqueName]="
                        field_ + j + '_' + section.sectionId + '_' + i
                      " [submitForm]="showErrors"></app-dynamic-fields>
                  </div>
                  <ng-container *ngFor="let sectionl of section.subSections; let c = index">
                    <div class="row col-12 mx-0 align-items-center mt-3 pt-3 border-top">
                      <button class="btn btn-black float-right ml-auto mr-2 mw-auto" *ngIf="section.allowMultiple"
                        (click)="deleteSection(section, sectionl)" type="button">
                        <i class="fi flaticon-cancel"></i>
                      </button>
                      <button class="btn btn-black float-right mw-auto" *ngIf="section.allowMultiple"
                        (click)="addMoreSection(section, selectedCategoryId)" type="button">
                        <i class="fi flaticon-plus font-18"></i>
                      </button>
                    </div>
                    <div class="row mx-0 w-100" ngModelGroup="collapseSubCatSec_{{ c }}_Group">
                      <div class="col-12" [ngClass]="{ 'col-md-6': !isPopup }" *ngFor="
                          let fieldl of sectionl.fields
                            | sortBy : 'asc' : 'displayOrder';
                          let p = index
                        ">
                        <app-dynamic-fields [field]="fieldl" [uniqueName]="
                            'field_' + c + '_' + section.sectionId + '_' + p
                          " [submitForm]="showErrors"></app-dynamic-fields>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="
              let subCategory of subcategoryList
                | sortBy : 'asc' : 'displayOrder';
              let i = index
            ">
            <ng-container *ngFor="let section of subCategory.sections; let j = index">
              <div class="tab-pane fade" [id]="'collapseSubcat_' + i + '_' + j" role="tabpanel"
                [attr.aria-labelledby]="'collapseSubcat_' + i + '_' + j + 'Tab'">
                <div class="row col-12 px-0 mx-0 align-items-center">
                  <h6 class="fw-600 d-none" [ngClass]="{ 'd-md-block': !isPopup }">
                    {{ section.name }}
                  </h6>
                  <button class="btn btn-black float-right ml-auto mw-auto" *ngIf="section.allowMultiple" (click)="
                      addSectionInSubcategory(
                        section,
                        subCategory.subCategoryId
                      )
                    " type="button">
                    <i class="fi flaticon-plus font-18"></i>
                  </button>
                </div>
                <div *ngIf="section.hintText != '' && section.hintText != null">
                  {{ section.hintText }}
                </div>
                <div class="row" ngModelGroup="collapseSubcat_{{ i }}_{{ j }}_Group">
                  <div class="col-12" [ngClass]="{ 'col-md-6': !isPopup }" *ngFor="
                      let field of section.fields
                        | sortBy : 'asc' : 'displayOrder';
                      let k = index
                    ">
                    <app-dynamic-fields [field]="field" [uniqueName]="
                        'field_' +
                        i +
                        '_' +
                        j +
                        '_' +
                        section.sectionId +
                        '_' +
                        k
                      " [submitForm]="showErrors"></app-dynamic-fields>
                  </div>
                  <ng-container *ngFor="let sectionl of section.subSections; let c = index">
                    <div class="row col-12 mx-0 align-items-center mt-3 pt-3 border-top">
                      <button class="btn btn-black float-right ml-auto mr-2 mw-auto" *ngIf="section.allowMultiple"
                        (click)="
                          deleteSectionInSubCategory(
                            section,
                            subCategory.subCategoryId,
                            sectionl
                          )
                        " type="button">
                        <i class="fi flaticon-cancel"></i>
                      </button>
                      <button class="btn btn-black float-right mw-auto" *ngIf="section.allowMultiple" (click)="
                          addSectionInSubcategory(
                            section,
                            subCategory.subCategoryId
                          )
                        " type="button">
                        <i class="fi flaticon-plus font-18"></i>
                      </button>
                    </div>
                    <div class="row mx-0 w-100" ngModelGroup="collapseSubCatSubSec_{{ c }}_Group">
                      <div class="col-12" [ngClass]="{ 'col-md-6': !isPopup }" *ngFor="
                          let fieldl of sectionl.fields
                            | sortBy : 'asc' : 'displayOrder';
                          let p = index
                        ">
                        <app-dynamic-fields [field]="fieldl" [uniqueName]="
                            'field_' + c + '_' + section.sectionId + '_' + p
                          " [submitForm]="showErrors"></app-dynamic-fields>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="col-md-12 pb-2 text-right bg-white mt-1"
        [ngClass]="{ 'sticky-bottom border-top lt-footer': isPopup }">
        <button *ngIf="showPreButton" (click)="previousTab()" type="button"
          class="btn btn-outline-black py-1 px-3 mt-2 mw-auto mr-2">
          Previous
        </button>
        <button *ngIf="showNextButton" (click)="nextTab()" type="button" class="btn py-1 px-3 mt-2 mw-auto mr-2"
          [ngClass]="{
            'btn-black': showPreButton || user.defaultProfileId != null,
            'btn-outline-black': !showPreButton && user.defaultProfileId == null
          }">
          {{
          showPreButton || user.defaultProfileId != null
          ? "Next"
          : "Add More to Profile"
          }}
        </button>
        <!-- <button *ngIf="showNextButton && selectedSubcategoryId.length != 0" (click)="nextTab()" type="button" class="btn btn-black py-1 px-3 mt-2 mw-auto">Next</button> -->
        <button *ngIf="showCreateButton" (click)="checkCreateProfile()" class="btn btn-black py-1 px-3 mt-2 mw-auto">
          Create
        </button>
        <button *ngIf="createMyPageNowButton && !showCreateButton && !showPreButton" (click)="checkCreateProfile()"
          class="btn btn-black py-1 px-3 mt-2 mw-auto">
          Finish and Create
        </button>
      </div>
    </form>
    <!-- .form -->
  </div>
  <!-- .container -->
</div>
<!-- .profile-content -->
<!-- claim Profile Ends -->

<!-- Success Modal -->
<div class="modal fade" id="successModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <h1 class="text-center success-color">
          <i class="fi flaticon-plus font-40"></i>
        </h1>
        <h6 class="text-center bold my-3 theme-color">Successfully Claimed</h6>
        <p class="text-center theme-color">
          Peter parker's page is successfully claimed ! Now, you can see in your
          Homepage.
        </p>
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-outline-black mt-4 mb-2 mx-2 py-1 font-12" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
      <!-- .modal-body -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog -->
</div>
<!-- #successModal -->
<!-- Success Modal Ends -->

<!-- View Profile Modal -->
<div class="modal fade p-0 view-profile-modal" id="viewProfileModal" tabindex="-1" role="dialog" aria-hidden="true">
  <button type="button" class="close font-32" aria-label="Close" (click)="modalClose('viewProfileModal')">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered modal-img-viewer" role="document">
    <div class="modal-content">
      <div class="modal-body row m-0 p-0">
        <img [src]="selectedImg" onerror="this.src='../../../assets/images/noprofile2.png';"
          class="img-fluid img-view m-auto no-profile-img" alt="Page Image" />
      </div>
      <!-- .modal-body -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog -->
</div>
<!-- #viewProfileModal -->
<!-- View Profile Modal Ends -->

<!-- uploadProfileModal Modal -->
<div class="modal fade" id="uploadProfileModal" tabindex="-1" role="dialog" aria-labelledby="uploadProfileModalTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered modal-theme modal-file-upload" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="uploadProfileModalTitle">
          Upload Picture
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modalClose('uploadProfileModal')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form (submit)="uploadProfile($event)" class="col-md-12 p-0" enctype="multipart/form-data" #uploadForm="ngForm"
          novalidate>
          <div class="row m-0">
            <div class="col-12">
              <div class="custom-file my-3">
                <input type="file" (change)="fileChangeEvent($event)" class="custom-file-input" accept="image/*"
                  #fileForUpload id="profileUpload" />
                <label class="custom-file-label" for="profileUpload">Choose file</label>
              </div>
              <div *ngIf="imageTypeInvalid" class="has-error text-center">
                <span>Only Images are Supported</span>
              </div>
            </div>
            <div class="col-md-12 row mx-0 mb-4 align-items-center" *ngIf="imageChangedEvent">
              <div class="col border p-0 mr-2">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                  [aspectRatio]="1 / 1" format="png" (imageCropped)="imageCropped($event)"></image-cropper>
              </div>
              <div class="col-auto p-0">
                <img [src]="cropedImg" onerror="this.src='../../../assets/images/noprofile2.png';"
                  class="img-fluid fix-avtar-7 br-10 border" />
              </div>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 text-center">
              <button type="submit" class="btn btn-black font-12 mr-2">
                Upload
              </button>
              <button type="reset" class="btn btn-outline-black font-12" (click)="modalClose('uploadProfileModal')">
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
      <!-- .modal-body imageTypeInvalid-->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog -->
</div>
<!-- #uploadProfileModal -->
<!-- uploadProfileModal Modal -->

<!-- Create page Modal -->
<div class="modal fade" id="createPageModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body py-5">
        <h1 class="text-center font-light-warning">
          <svg xmlns="http://www.w3.org/2000/svg" class="fix-avtar-4" viewBox="0 0 80.891 80.445">
            <g id="Group_922" data-name="Group 922" transform="translate(0 -0.446)">
              <ellipse id="Ellipse_28" data-name="Ellipse 28" cx="5" cy="4.5" rx="5" ry="4.5"
                transform="translate(35.446 0.446)" fill="#e4cd00" />
              <path id="Path_316" data-name="Path 316"
                d="M93.635,96.8A3.16,3.16,0,0,0,95.869,91.4L67.826,63.358l-4.469,4.469L91.4,95.87A3.151,3.151,0,0,0,93.635,96.8Z"
                transform="translate(-53.347 -53.348)" fill="#e4cd00" />
              <path id="Path_317" data-name="Path 317"
                d="M71.624,22.107A40.6,40.6,0,0,0,50.986,8.82a11.057,11.057,0,0,1,.031,6.6A34.109,34.109,0,1,1,10.552,31.384L5.93,26.763A40.453,40.453,0,0,0,69.045,76.472a40.448,40.448,0,0,0,2.579-54.364Z"
                transform="translate(0 -7.427)" fill="#e4cd00" />
            </g>
          </svg>
        </h1>
        <h2 class="text-center bold my-3 px-3 px-sm-5 font-light-warning">
          Wait !
        </h2>
        <h4 class="text-center theme-color px-3 px-sm-5 lh-1-7">
          Do you wish to just create it or you also wish to claim this profile ?
        </h4>
        <div class="col-12 text-center">
          <button type="submit" class="btn btn-black mt-4 mb-2 mx-2 py-1 font-12" data-dismiss="modal"
            (click)="createProfile('create')">
            Just Create it
          </button>
          <button type="submit" class="btn btn-outline-black mt-4 mb-2 mx-2 py-1 font-12" data-dismiss="modal"
            (click)="createProfile('createWithClaimed')">
            Create and claim
          </button>
        </div>
      </div>
      <!-- .modal-body -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog -->
</div>
<!-- #successModal -->
<!-- Success Modal Ends -->
